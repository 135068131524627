// Based on https://hasura.io/learn/graphql/nextjs-fullstack-serverless/apollo-client/

import { ApolloClient, ApolloLink, from, HttpLink, NormalizedCacheObject } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { NextPageContext } from 'next'
import getConfig from 'next/config'

import { cache } from '~/graphql/cache'
import { apolloErrorHandler, authHandler, requiredHeadersHandler } from '~/utils/apolloUtils'

const { publicRuntimeConfig } = getConfig()

// TODO: move of hasura dev admin token and use a JWT once we have authentication working
// let accessToken: any = null
//
// const requestAccessToken = async () => {
//   if (accessToken) return
//   const res = await fetch(`${process.env.APP_HOST}/api/session`)
//   if (res.ok) {
//     const json = await res.json()
//     accessToken = json.accessToken
//   } else {
//     accessToken = 'public'
//   }
//   accessToken = ''
// }

// remove cached token on 401 from the server
// const resetTokenLink = onError(({ networkError }) => {
//   if (networkError && networkError.name === 'ServerError' && (networkError as ServerError).statusCode === 401) {
//     accessToken = null
//   }
// })

const createHttpLink = (ctx?: NextPageContext) => {
  const httpLink = new HttpLink({
    uri: publicRuntimeConfig.graphQL.endpoint, // endpoint should include https:// or http:// for localhost
    credentials: 'include',
    headers: ctx?.req?.headers || {}, // auth token is fetched on the server side
    fetch,
  })

  return httpLink
}

export default function createApolloClient(initialState: NormalizedCacheObject = {}, ctx?: NextPageContext) {
  const ssrMode = typeof window === 'undefined'
  const connectToDevTools = process.env.NODE_ENV === 'development'

  const httpLink = createHttpLink(ctx)
  const authLink = new ApolloLink(authHandler)
  const requiredHeadersLink = new ApolloLink(requiredHeadersHandler)
  const errorLink: ApolloLink = onError(apolloErrorHandler)
  const retryLink: ApolloLink = new RetryLink({
    attempts: {
      max: 3,
    },
  })

  return new ApolloClient({
    ssrMode,
    link: ssrMode ? from([errorLink, httpLink]) : from([authLink, requiredHeadersLink, errorLink, retryLink, httpLink]),
    cache: cache.restore(initialState),
    connectToDevTools,
  })
}
